<template>
  <div class="container-fluid main-color">
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">New Allocation</h4>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <place-holder v-if="loading"></place-holder>
        <div class="card">
          <div v-if="!loading" class="card-body">
            <form class="parsley-examples">
              <div class="row">
                <div class="col-md-7">
                  <div class="row mb-2">
                    <label for="level" class="col-md-3 form-label">
                      Program Name
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                      <v-select
                        :disabled="
                          currentRouteName == 'program-allocation-update'
                        "
                        @option:selected="getSelectedProgram"
                        :selectable="(options) => selectedProgram?.id != options?.id "
                        v-model="selectedProgram"
                        label="name"
                        :options="programs"
                        :clearable="false"
                        :class="{
                          'p-invalid':
                            v$.selectedProgram.$error || errorFor('name'),
                        }"
                      >
                      </v-select>
                      <v-errors
                        :serverErrors="errorFor('name')"
                        :vuelidateErrors="{
                          errors: v$.selectedProgram.$errors,
                          value: 'Program Name',
                        }"
                      ></v-errors>
                    </div>
                  </div>

                  <div class="row mb-2">
                    <label for="short_name" class="col-md-3 form-label">
                      Batch
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                      <v-select
                        @option:selected="getSelectedBatch"
                        :disabled="
                          currentRouteName == 'program-allocation-update'
                        "
                        v-model="selectedBatch"
                        label="batch_no"
                        :options="batch"
                        :selectable="(options) => isSelectable(options)"
                        :clearable="false"
                        :class="{
                          'p-invalid':
                            v$.selectedBatch.$error || errorFor('name'),
                        }"
                      >
                      </v-select>
                      <v-errors
                        :serverErrors="errorFor('name')"
                        :vuelidateErrors="{
                          errors: v$.selectedBatch.$errors,
                          value: 'Batch',
                        }"
                      ></v-errors>
                    </div>
                  </div>
                </div>

                <div class="row col-md-5">
                  <div class="row mb-2">
                    <label for="list" class="col-md-5 form-label">
                      Man Power List
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-6">
                      <input
                        type="number"
                        id="list"
                        name="list"
                        class="form-control"
                        min="1"
                        oninput="this.value = !!this.value && Math.abs(this.value) >= 1 ? Math.abs(this.value) : null"
                        placeholder="Man Power List"
                        v-model="allocation.man_power_list"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="float-end">
                <button
                  v-show="!loading"
                  type="button"
                  @click="addTimeline()"
                  class="btn btn-sm btn-blue waves-effect waves-light mb-2"
                >
                  <i class="mdi mdi-plus-circle"></i> Add
                </button>
              </div>

              <div
                class="progress mt-5"
                style="height: 0.5px; background-color: #ccc !important"
              >
                <div class="progress-bar" role="progressbar"></div>
              </div>

              <div class="row mt-2 text-center">
                <div class="d-flex">
                  <div class="col-md-3">
                    <label for="name" class="form-label">
                      Level
                      <span class="text-danger">*</span>
                    </label>
                  </div>
                  <div class="col-md-1">
                    <label for="name" class="form-label">
                      Type
                      <span class="text-danger">*</span>
                    </label>
                  </div>
                  <div class="col-md-4">
                    <label for="name" class="form-label">
                      From Date
                      <span class="text-danger">*</span>
                    </label>
                  </div>
                  <div class="col-md-1">
                    <label for="name" class="form-label">
                      To_date
                      <span class="text-danger">*</span>
                    </label>
                  </div>
                </div>
              </div>

              <div class="row mb-4">
                <div
                  v-for="(item, index) in allocation.program_allocation_details"
                  :key="index"
                  class="d-flex m-2"
                >
                  <div class="col-md-2 me-4">
                    <v-select
                      v-model="item.level"
                      label="name"
                      :options="levels"
                      :reduce="(name) => name.name"
                      :clearable="false"
                    >
                    </v-select>
                  </div>
                  <div class="col-md-2 me-4">
                    <v-select
                      v-model="item.type"
                      label="name"
                      :options="types"
                      :reduce="(name) => name.name"
                      :clearable="false"
                    >
                    </v-select>
                  </div>
                  <div class="col-md-2 me-4">
                    <flat-pickr
                      v-model="item.from_date"
                      class="form-control"
                      placeholder="Select From Date"
                      name="date"
                      :config="flatPickrDateConfig"
                    ></flat-pickr>
                  </div>
                  <div class="col-md-2 me-4">
                    <flat-pickr
                      v-model="item.to_date"
                      class="form-control"
                      placeholder="Select From Date"
                      name="date"
                      :config="flatPickrDateConfig"
                    ></flat-pickr>
                  </div>
                  <div class="col-md-2 ms-4" v-show="index != 0">
                    <div v-show="!loading" @click="removeTimeline(index)">
                      <i class="fas fa-trash-alt mx-1"></i>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end row -->
              <div class="row">
                <div class="col-md-12">
                  <div class="text-center mt-3 mb-3">
                    <router-link :to="{ name: 'program-allocation' }">
                      <button type="button" class="btn w-sm btn-secondary me-5">
                        Cancel
                      </button>
                    </router-link>
                    <button
                      :disabled="isLoading ? true : false"
                      type="button"
                      class="btn w-sm btn-success waves-effect waves-light px-3"
                      @click="
                        currentRouteName === 'program-allocation-update'
                          ? updateTimeline()
                          : createTimeline()
                      "
                    >
                      <span
                        v-if="isLoading"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      {{ isLoading == true ? "Loading..." : "Save" }}
                    </button>
                  </div>
                </div>
                <!-- end col -->
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import validationErrors from "../../../mixins/validationErrors";

export default {
  setup() {
    const toast = useToast();
    return { v$: useVuelidate(), toast };
  },
  components: {
    vSelect,
    flatPickr,
  },
  mixins: [validationErrors],
  data() {
    return {
      programs: [],
      allocationList: [],
      batch: [],
      levels: [],
      types: [
        {
          id: 1,
          name: "Orientation",
        },
        {
          id: 2,
          name: "InClass",
        },
        {
          id: 3,
          name: "OJT",
        },
      ],
      flatPickrDateConfig: {
        minDate: null,
        maxDate: null,
        defaultDate: null,
      },
      allocation: {
        program: {
          id: "",
          code: "",
          name: "",
        },
        batch: {
          id: "",
          batch_no: "",
        },
        man_power_list: "",
        program_allocation_details: [
          {
            level: "",
            type: "",
            from_date: "",
            to_date: "",
          },
        ],
      },
      selectedProgram: null,
      selectedBatch: null,
      baseUrl: process.env.VUE_APP_BASE_URL,
      baseUrlHRIS: process.env.VUE_APP_COURSIA_BASE_URL,
      loading: false,
      isLoading: false,
    };
  },
  validations() {
    return {
      selectedProgram: { required },
      selectedBatch: { required },
      allocation: {
        program: {
          id: { required },
          code: { required },
          name: { required },
        },
        batch: {
          id: { required },
          batch_no: { required },
        },
        man_power_list: { required },
      },
    };
  },
  methods: {
    addTimeline() {
      this.allocation.program_allocation_details.push({
        level: "",
        type: "",
        from_date: "",
        to_date: "",
      });
    },
    removeTimeline(index) {
      this.allocation.program_allocation_details.splice(index, 1);
    },
    getSelectedProgram() {
      if (this.selectedProgram) {
        this.allocation.program.id = this.selectedProgram.id;
        this.allocation.program.name = this.selectedProgram.name;
        this.allocation.program.code = this.selectedProgram.short_name;
        this.getBatch(this.allocation.program.id);
        this.selectedBatch = null;
      }
    },
    getSelectedBatch() {
      if (this.selectedBatch) {
        this.allocation.batch.id = this.selectedBatch.id;
        this.allocation.batch.batch_no = this.selectedBatch.batch_no;
      }
    },
    async getPrograms() {
      this.loading = true;
      await axios
        .get(`${this.baseUrl}admin/v1/programs`)
        .then((response) => {
          this.programs = response.data.data;
          this.loading = false;
        })
        .catch(() => {
          this.toast.error("Not Found Program!");
        });
    },
    async getBatch(program_id) {
      this.loading = true;
      await axios
        .get(
          `${this.baseUrl}admin/v1/program-batches/list?program_id=${program_id}`
        )
        .then((response) => {
          this.batch = response.data.data;
          this.loading = false;
        })
        .catch(() => {
          this.toast.error("Not Found Batch!");
        });
    },
    async getAllLevels() {
      this.loading = true;
      await axios.get(`${this.baseUrl}admin/v1/levels`).then((response) => {
        this.levels = response.data.data;
        this.loading = false;
      });
    },
    async getAllocationById() {
      this.loading = true;
      await axios
        .get(
          `${this.baseUrl}admin/v2/program-allocation/${this.$route.params.id}`
        )
        .then((response) => {
          const result = response.data.data;
          this.allocation = result;
          this.selectedProgram = {
            id: result.program.id,
            name: result.program.name,
            short_name: result.program.code,
          };
          this.selectedBatch = {
            id: result.batch.id,
            batch_no: result.batch.batch_no,
          };
          this.allocation.program_allocation_details =
            result.program_allocation_details.map((item) => {
              return {
                level: item.level,
                type: item.type,
                from_date: item.from_date,
                to_date: item.to_date,
              };
            });
          this.getBatch(this.selectedProgram.id);
        })
        .catch(() => {
          this.toast.error("Not Found Data!");
        });
      this.loading = false;
    },

    checkEmptyValue(array) {
      let allocationData = Object.values(array);
      if (allocationData.includes(null) || allocationData.includes("")) {
        return false;
      } else {
        return true;
      }
    },

    async createTimeline() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;

      let checkTimeline = [];
      this.allocation.program_allocation_details.forEach((array) => {
        let allocation_data = this.checkEmptyValue(array);
        checkTimeline.push(allocation_data);
      });
      if (checkTimeline.includes(false)) {
        this.toast.error("Please Fill All Input!");
        return;
      }
      this.isLoading = true;

      let data = {
        program: this.allocation.program,
        batch: this.allocation.batch,
        program_id: this.allocation.program.id,
        batch_id: this.allocation.batch.id,
        man_power_list: this.allocation.man_power_list,
        program_allocation_details: this.allocation.program_allocation_details,
      };

      await axios
        .post(`${this.baseUrl}admin/v2/program-allocation`, data)
        .then(() => {
          this.$router.push({ name: "program-allocation" });
          this.toast.success("Successfully Created Timeline!");
          this.isLoading = false;
        })
        .catch((error) => {
          this.errors = error.response;
          this.toast.error("Something Went Wrong!");
          this.isLoading = false;
        });
    },

    async updateTimeline() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;

      let checkTimeline = [];
      this.allocation.program_allocation_details.forEach((array) => {
        let allocation_data = this.checkEmptyValue(array);
        checkTimeline.push(allocation_data);
      });
      if (checkTimeline.includes(false)) {
        this.toast.error("Please Fill All Input!");
        return;
      }

      let data = {
        program: this.allocation.program,
        batch: this.allocation.batch,
        program_id: this.allocation.program.id,
        batch_id: this.allocation.batch.id,
        man_power_list: this.allocation.man_power_list,
        program_allocation_details: this.allocation.program_allocation_details,
      };
      this.isLoading = true;
      axios
        .put(
          `${this.baseUrl}admin/v2/program-allocation/${this.$route.params.id}`,
          data
        )
        .then(() => {
          this.$router.push({ name: "program-allocation" });
          this.toast.success("Successfully Allocation Updated!");
          this.isLoading = false;
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.toast.error("Something Went Wrong!");
          this.isLoading = false;
        });
    },

    async getAllProgramAllocation() {
      this.loading = true;
      await axios
        .get(`${this.baseUrl}admin/v2/program-allocation`)
        .then((response) => {
          this.allocationList = response.data.data;
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          this.toast.error("Something Went Wrong!");
        });
    },
    isSelectable(options) {
            if (this.selectedBatch) {
                // Check if the program with the same id exists in allocationList
                const batchExists = this.allocationList?.some((item) => item.program_batch_id == this.selectedBatch.id)
                // If the batch doesn't exist, add it to allocationList
                if (!batchExists) {
                    this.allocationList = this.allocationList.filter(
                        (item) => !item.is_selected
                    );
                    this.allocationList.push({
                      program_id: this.selectedBatch.program.id,
                      program_batch_id: this.selectedBatch.id,
                      program_batch_no: this.selectedBatch.batch_no,
                      is_selected: true,
                    });
                }
            }
            // Now, check if the options.id exists in allocationList
            return !this.allocationList.some(
                (item) => item.program_id === this.selectedProgram.id && item.program_batch_id == options.id
            );
        },
  },
  async created() {
    await this.getAllProgramAllocation();
    await this.getPrograms();
    this.getAllLevels();
    if (this.currentRouteName == "program-allocation-update") {
      this.getAllocationById();
    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>

<style>
.custom-selectCSS {
  --vs-dropdown-min-width: 160px;
  --vs-dropdown-max-height: 200px;
}
</style>
